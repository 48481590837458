import { isValidArrayWithData } from '@smu-chile/pkg-unimarc-hooks'
import {
  ICart,
  ICartProduct
} from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'

/**
 * @function getCategoryFromProducts
 * @param {ICart} data - from get
 * @param {string} categoryNameSearch - its a string related to a specific category
 * @description obtain products from an specific category
 */

export const getCategoryFromProducts = (
  data: ICart,
  categoryNameSearch = 'Todos'
): ICartProduct | Record<string, never> => {
  return isValidArrayWithData(data?.products)
    ? data.products.find(({ categoryName }) => {
        return categoryName === categoryNameSearch
      })
    : {}
}
