export const shouldOpenLoginModal = (
  routerIsReady: boolean,
  loginQuery: string | string[],
  isLoading: boolean,
  isLoggedIn: boolean
): boolean => {
  return routerIsReady && !isLoading
    ? !isLoggedIn && loginQuery === 'true'
    : false
}
