import {
  Column,
  Container,
  Divider,
  Modal
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  getBemId,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useTrigerEvent } from '@smu-chile/pkg-unimarc-hooks'
import { ProductNote } from './ProductNote'
import styles from './ModalMessage.module.css'
import { ModalMessageHeader } from './ModalMessageHeader'

export interface ModalMessageItem {
  brand?: string
  id: string
  imageUrl: string
  measurement: string
  name: string
}

interface ModalMessageProps {
  isOpen?: boolean
  item: ModalMessageItem
}
export const ModalMessage = ({
  item,
  isOpen = true
}: ModalMessageProps): React.ReactElement => {
  const { trigger } = useTrigerEvent()

  const closeModalNote = () => {
    trigger({
      eventType: 'productNote',
      data: {
        show: false,
        item: {
          id: item.id
        }
      }
    })
  }

  return (
    <>
      <BigScreen>
        <Modal
          hiddenX
          isOpen={isOpen}
          maxWidth='495px'
          minHeight='438px'
          modalConfigsProps={{
            containerId: getBemId('modalMessage', 'container')
          }}
          padding='0px'
          toggle={closeModalNote}
          toggleOutside={closeModalNote}
        >
          <Container
            isWrap
            overflow='hidden'
          >
            <ModalMessageHeader closeModalNote={closeModalNote} />
            <Divider
              color={getGlobalStyle('--color-neutral-gray-divider')}
              verticalMargin={0}
            />
            <ProductNote item={item} />
          </Container>
        </Modal>
      </BigScreen>

      <SmallScreen>
        <Modal
          borderRadius={`${getGlobalStyle(
            '--border-radius-md'
          )} ${getGlobalStyle('--border-radius-md')} 0px 0px `}
          className={styles.modal_mobile}
          hiddenX
          isOpen={isOpen}
          maxWidth='495px'
          minHeight='576px'
          modalConfigsProps={{
            containerId: getBemId('modalMessage', 'container')
          }}
          padding='0px'
          toggle={closeModalNote}
          toggleOutside={closeModalNote}
        >
          <Column>
            <Container
              backgroundColor={getGlobalStyle('--color-neutral-white')}
              borderRadius={getGlobalStyle('--border-radius-md')}
              isWrap
              minWidth='100%'
            >
              <ModalMessageHeader closeModalNote={closeModalNote} />
              <Divider
                color={getGlobalStyle('--color-neutral-gray-divider')}
                verticalMargin={0}
              />
              <ProductNote item={item} />
            </Container>
          </Column>
        </Modal>
      </SmallScreen>
    </>
  )
}
