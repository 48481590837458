export const IMG_CLEAN_CART = {
  imgGarlic: 'PPGHfbCON1V2LjdYnfk9V',
  imgOnion: '6hECGKrqo2oJLAQvBRyAda',
  imgApple: '3cbneoeVGhwZflsU7FARyN',
  imgDonuts: '5k0xfe6ACwFX5hrCQNAcFs'
}

export const ADDRESS_ICONS = {
  imgByke: '684KQo1Il6Mgj0Cj4SwFRd',
  imgStore: '4Hsg5W7ayBDrMB1pLNzjwP'
}

export const ERROR_MESSAGES = {
  errorCleaningCart:
    '¡Lo sentimos! No pudimos vaciar tu carro vuelve a intentarlo.',
  errorChangingQuantity: 'No pudimos modificar tu carro. Inténtalo otra vez.'
}
