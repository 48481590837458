import {
  Horizontal,
  Text,
  Modal,
  Icon,
  Divider,
  Button,
  Container,
  Column
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  dataLayerProductEvent,
  isValidArrayWithData
} from '@smu-chile/pkg-unimarc-hooks'
import { useEffect, useState } from 'react'
import { ICart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { ResponseOrderFormProps } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrderForm'
import stylesModal from './NoStockModal.module.css'

export interface NoStockModalProps {
  data: ICart
  orderFormData: ResponseOrderFormProps
  isOpen?: boolean
  onClick?: () => void
}

interface ItemsWarning {
  id?: string
  message?: string
}

export const NoStockModal = ({
  data,
  isOpen = true,
  orderFormData,
  onClick
}: NoStockModalProps) => {
  const [itemsWarning, setItemsWarning] = useState<ItemsWarning[]>([])
  const dataOrderform = orderFormData?.data

  useEffect(() => {
    const productsAlerts = [...data.removedProducts]
    setItemsWarning(productsAlerts)
  }, [data?.removedProducts])

  useEffect(() => {
    if (itemsWarning.length > 0) {
      const prevProducts =
        itemsWarning.length > 0
          ? itemsWarning.length + data?.products?.[0]?.totals
          : data?.products?.[0]?.totals
      if (data?.removedProducts) {
        dataLayerProductEvent({
          assigned_store: `Unimarc ${dataOrderform?.selectedAddresses?.neighborhood}`,
          carro_prev: prevProducts,
          carro_res: data?.products?.[0]?.totals,
          context: 'modal sin stock',
          currency: 'CLP',
          event: 'remove_from_cart',
          items:
            Array.isArray(data?.removedProducts) &&
            data?.removedProducts?.map((element) => {
              return {
                item_id: element?.id,
                item_name: element?.message,
                quantity: 0
              }
            }),
          value: data?.subtotal
        })
      }
    }
  }, [data?.removedProducts])

  if (itemsWarning.length === 0) {
    return null
  }

  return (
    <Modal
      hiddenX
      isOpen={isOpen}
      maxWidth='385px'
      modalConfigsProps={{
        containerId: getBemId('noStockModal', 'container')
      }}
      toggle={null}
      toggleOutside={null}
    >
      <Container
        id={getBemId('noStockModal', 'icon')}
        justifyContent='center'
      >
        <Icon
          customSize={40}
          name='Error'
        />
      </Container>
      <Horizontal size={2} />

      <div className={stylesModal.modalContainer}>
        <Text
          fontSize='md'
          fontWeight='medium'
          id={getBemId('noStockModal', 'title')}
          isFlex
          textAlign='center'
          truncate={100}
        >
          Lo sentimos, tuvimos algunos problemas con algunos productos que
          agregaste al carro.
        </Text>

        <Horizontal size={16} />

        <Column id={getBemId('noStockModal', 'itemsContainer')}>
          {isValidArrayWithData(itemsWarning) &&
            itemsWarning.map((warning) => {
              return (
                <div key={warning.message}>
                  <Divider
                    color={getGlobalStyle('--color-neutral-gray-silver')}
                    thickness={1}
                    verticalMargin={9}
                  />
                  <Text
                    color='gray'
                    fontSize='md'
                    fontWeight='medium'
                    id={getBemId('noStockModal', 'message')}
                    isFlex
                    textAlign='justify'
                  >
                    {warning?.['message']}
                  </Text>
                </div>
              )
            })}
        </Column>
      </div>

      <Horizontal size={24} />

      <Button
        id={getBemId('noStockModal', 'button')}
        label='Continuar sin estos productos'
        onClick={onClick}
        size='large'
        width='310px'
      />
    </Modal>
  )
}
