import {
  Container,
  Spacer,
  ErrorView,
  ErrorViewProps
} from '@smu-chile/pkg-unimarc-components'
import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { handleWindowReload } from 'shared/helpers'

export const ErrorPage = ({
  body1,
  body2,
  buttonLabel,
  errorImage,
  errorType,
  redirect,
  title
}: Omit<ErrorViewProps, 'onClick'>): React.ReactElement => {
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280

  const errorViewProps = {
    body1,
    body2,
    buttonLabel,
    errorImage,
    errorType,
    redirect,
    title
  }

  return (
    <Container
      alignItems='center'
      backgroundColor={getGlobalStyle('--color-background-white')}
      justifyContent='center'
      minHeight={!isMobile && '543px'}
      tagName='section'
    >
      <Container
        backgroundColor={getGlobalStyle('--color-background-white')}
        customWidth='90'
        isWrap
        justifyContent='center'
        maxWidth='1440px'
      >
        <Spacer.Horizontal size={96} />
        <ErrorView
          {...errorViewProps}
          onClick={handleWindowReload}
        />
        <Spacer.Horizontal size={160} />
      </Container>
    </Container>
  )
}
