import {
  Button,
  Column,
  Container,
  Icon,
  Modal,
  Picture,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useContentful, useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { IMG_CLEAN_CART } from 'components/Body/helper/constants'
import styles from './ModalConfirmation.module.css'

interface ModalConfirmationProps {
  onCancel: () => void
  onDelete: () => void
}

export const ModalConfirmation = ({
  onCancel,
  onDelete
}: ModalConfirmationProps): React.ReactElement => {
  const { isMobile } = useMobile()
  const { data } = useContentful({
    id_contentful: 'contentful-image-delete-msg-apple',
    options: {
      'sys.id': IMG_CLEAN_CART.imgApple
    },
    type: 'assets'
  })
  const appleImgage = `https:${data?.['items'][0]?.fields?.file?.url || ''}`
  const maxheight = isMobile ? '576px' : '447px'
  const heightImage = isMobile ? '194px' : '130px'
  const widthImage = isMobile ? '310px' : '209px'
  const paddingContainer = isMobile ? '24px 24px 0' : '24px'
  const sizeIcon = isMobile ? 16.95 : 20

  return (
    <Modal
      className={styles['modalConfirmation__overflow']}
      customContainerClassName={styles['modalConfirmation__container']}
      hiddenX
      isOpen={true}
      maxHeight={maxheight}
      maxWidth='495px'
      minHeight={maxheight}
      modalConfigsProps={{
        containerId: getBemId('removeComment', 'container')
      }}
      padding='0px'
      toggle={onCancel}
      toggleOutside={onCancel}
    >
      <Container
        isWrap
        padding={paddingContainer}
      >
        <Row
          id={getBemId('removeComment', 'closeIcon')}
          justifyContent='end'
        >
          <Icon
            clickable='pointer'
            color={getGlobalStyle('--color-base-black')}
            customSize={sizeIcon}
            name='Close'
            onClick={onCancel}
          />
        </Row>
        <Row
          justifyContent='center'
          maxHeight={heightImage}
        >
          <Picture
            height={heightImage}
            id={getBemId('removeCommentModal', 'image')}
            objectFit='contain'
            src={appleImgage}
            width={widthImage}
          />
        </Row>
        <Spacer.Horizontal size={16} />
        <Row>
          <Column alignItems='center'>
            <Text
              color='neutral-black'
              fontSize='xl'
              fontWeight='semibold'
              id={getBemId('removeComment', 'title')}
            >
              Eliminar comentario
            </Text>
            <Spacer.Horizontal size={16} />
            <Row maxWidth='270px'>
              <Text
                color='neutral-black'
                fontSize='lg'
                id={getBemId('removeComment', 'subtitle')}
                textAlign='center'
              >
                ¿Estás seguro que quieres eliminar tu comentario?
              </Text>
            </Row>
          </Column>
        </Row>
        <Spacer.Horizontal size={32} />
        <Spacer.Horizontal size={4} />
        <Row>
          <Column
            justifyContent='end'
            minHeight={isMobile && '165px'}
          >
            <Row justifyContent='center'>
              <Column>
                <Button
                  borderRadius={getGlobalStyle('--border-radius-xl')}
                  fontWeight={getGlobalStyle('--font-weight-medium')}
                  height='40px'
                  id={getBemId('removeComment', 'primaryButton')}
                  label='Sí, eliminar'
                  onClick={onDelete}
                  size='medium'
                  status='initial'
                  width='100%'
                />
              </Column>
            </Row>
            <Spacer.Horizontal size={16} />
            <Row justifyContent='center'>
              <Column>
                <Button
                  borderRadius={getGlobalStyle('--border-radius-xl')}
                  fontWeight={getGlobalStyle('--font-weight-medium')}
                  height='40px'
                  id={getBemId('removeComment', 'secondaryButton')}
                  label='No, gracias'
                  onClick={onCancel}
                  size='medium'
                  status='initial'
                  type='secondary'
                  width='100%'
                />
              </Column>
            </Row>
          </Column>
        </Row>
      </Container>
    </Modal>
  )
}
