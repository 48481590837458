import { Column, Icon, Row, Text } from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import React from 'react'

interface ModalMessageHeaderProps {
  closeModalNote?: () => void
}

export const ModalMessageHeader = ({
  closeModalNote
}: ModalMessageHeaderProps) => {
  const { isMobile } = useMobile()
  const fontWeight = isMobile ? 'medium' : 'semibold'
  const sizeIcon = isMobile ? 16.95 : 20
  return (
    <Row
      alignItems='center'
      justifyContent='between'
      maxHeight='68px'
      minHeight='68px'
      padding='20px 25.53px 20px 34px'
    >
      <div />
      <Column maxWidth='max-content'>
        <Text
          color='neutral-black'
          fontSize='lg'
          fontWeight={fontWeight}
          id={getBemId('modalMessage', 'title')}
        >
          ¿Te gustaría algo en especial?
        </Text>
      </Column>
      <Column
        id={getBemId('modalMessage', 'closeIcon')}
        maxWidth='max-content'
      >
        <Icon
          clickable='pointer'
          color={getGlobalStyle('--color-base-black')}
          customSize={sizeIcon}
          data-testid='Close'
          name='Close'
          onClick={closeModalNote}
        />
      </Column>
    </Row>
  )
}
