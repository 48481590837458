import { useEffect, useState, ChangeEvent } from 'react'
import {
  Button,
  Column,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  getBemId,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import {
  formatURLfromVtex,
  useMobile,
  useTrigerEvent
} from '@smu-chile/pkg-unimarc-hooks'
import NextImage from 'next/image'
import storageNotes from 'shared/utils/storageNotes/storageNotes'
import { ModalMessageItem } from '.'
import { ModalConfirmation } from './ModalConfirmation'
import styles from './ModalMessage.module.css'

interface ProductNoteProps {
  isOpen?: boolean
  item: ModalMessageItem
}

const imageNextJsLoader = ({ src }: { src: string }) => {
  return `${src}&q=75`
}

export const ProductNote = ({
  item,
  isOpen = true
}: ProductNoteProps): React.ReactElement => {
  const { brand, id, imageUrl, measurement, name } = item
  const [text, setText] = useState('')
  const [modalConfirmarion, setModalConfirmation] = useState(false)
  const { innerWidth } = useMobile()
  const isMobile = innerWidth < 1280
  const { trigger } = useTrigerEvent()
  const reBuildedImgSrc: string = formatURLfromVtex(imageUrl, 250, 250)
  const truncateText = isMobile ? 50 : 90
  const heigthModal = isMobile ? '144px' : '106px'

  const handleText = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    evt.preventDefault()
    setText(`${evt.target.value}`)
  }

  const saveNote = () => {
    storageNotes.addProductNote(id, text)
    trigger({
      eventType: 'productNoteNotification',
      data: {
        show: true,
        txt: 'Tu comentario se agregó exitosamente.'
      }
    })
    closeModalNote()
  }

  const closeModalNote = () => {
    trigger({
      eventType: 'productNote',
      data: {
        show: false,
        item: {
          id
        }
      }
    })
  }

  const removeNote = () => {
    storageNotes.removeProductNote(item.id)
    trigger({
      eventType: 'productNoteNotification',
      data: {
        show: true,
        txt: 'Tu comentario se eliminó.'
      }
    })
    closeModalNote()
  }

  const closeModalConfirmation = () => {
    setModalConfirmation(false)
  }

  const openModdelConfirmation = () => {
    if (text) setModalConfirmation(true)
  }

  useEffect(() => {
    const productNote = storageNotes.getNoteByProductId(id)
    setText(productNote)
  }, [isOpen])

  return (
    <>
      {modalConfirmarion && (
        <ModalConfirmation
          onCancel={closeModalConfirmation}
          onDelete={removeNote}
        />
      )}
      <Row className={styles.container_modal}>
        <Column
          minHeight={isMobile && '480px'}
          padding='30px 24px 24px'
        >
          <Row alignItems='center'>
            <Column
              maxHeight='100px'
              maxWidth='100px'
            >
              <NextImage
                alt={name}
                height={100}
                id={getBemId('modalMessage', 'image')}
                loader={imageNextJsLoader}
                objectFit='cover'
                src={reBuildedImgSrc}
                width={100}
              />
            </Column>
            <Spacer.Vertical size={24} />
            <Column customWidth='100'>
              {brand && (
                <Text
                  fontSize='lg'
                  fontWeight='semibold'
                  id={getBemId('modalMessage', 'brandName')}
                  textTransform='capitalize'
                >
                  {`${brand}`.toLowerCase()}
                </Text>
              )}
              <Text
                color='neutral'
                customClassName={styles.name}
                fontSize='md'
                id={getBemId('modalMessage', 'name')}
                lineClamp={1}
                truncate={truncateText}
              >
                {name}
              </Text>
              <Text
                color='gray'
                fontSize='sm'
                id={getBemId('modalMessage', 'measurementUnit')}
              >
                {measurement}
              </Text>
            </Column>
          </Row>
          <Spacer.Horizontal size={isMobile ? 24 : 16} />
          <Row>
            <Column
              border={`1px solid ${getGlobalStyle('--color-neutral-gray')}`}
              borderRadius={getGlobalStyle('--border-radius-md')}
              maxHeight={heigthModal}
              maxWidth='447px'
              minHeight={heigthModal}
              padding='11px 16px'
              position='relative'
            >
              <textarea
                className={styles.modalmessage__textarea}
                cols={50}
                id={getBemId('modalMessage', 'textarea')}
                inputMode='text'
                maxLength={150}
                onChange={handleText}
                placeholder='Indícanos aquí si quieres que tu producto esté maduro, buscas algún corte, color, tamaño o unidades.'
                rows={5}
                value={text}
              />
              <Row
                id={getBemId('modalMessage', 'trashIcon')}
                margin='0 0 0 auto'
                maxHeight='max-content'
                maxWidth='max-content'
                onClick={openModdelConfirmation}
              >
                <Icon
                  clickable='pointer'
                  color={getGlobalStyle('--color-primary-light-red')}
                  customSize={20}
                  name='Trash'
                  strokeWidth='1'
                />
              </Row>
            </Column>
          </Row>
          <Spacer.Horizontal size={32} />
          <Spacer.Horizontal size={4} />
          <Row margin='auto 0 0 0'>
            <Column>
              <Button
                borderRadius={getGlobalStyle('--border-radius-xl')}
                fontWeight={getGlobalStyle('--font-weight-medium')}
                height='40px'
                id={getBemId('modalMessage', 'button')}
                label='Agregar comentario'
                onClick={saveNote}
                size='medium'
                status={text.length > 0 ? 'initial' : 'disabled'}
                width='100%'
              />
            </Column>
          </Row>
        </Column>
      </Row>
    </>
  )
}
