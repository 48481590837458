import { useRouter } from 'next/router'
import Link from 'next/link'
import { MiniFooter } from '@smu-chile/pkg-unimarc-components'
import {
  IEntriesProps,
  getMiniFooterContent,
  isValidArrayWithData
} from '@smu-chile/pkg-unimarc-hooks'
import { IMiniFooter } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMiniFooter'

interface FooterProps {
  data: IEntriesProps
}

export const Footer = ({ data }: FooterProps) => {
  const router = useRouter()
  const footerData: IMiniFooter[] = getMiniFooterContent(
    data,
    router
  ) as IMiniFooter[]
  if (isValidArrayWithData(footerData))
    return (
      <MiniFooter
        data={footerData}
        linkWrapper={Link}
        margin='68px 0 0'
      />
    )
  return null
}
