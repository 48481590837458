import { Cookies, getCurrentDomain } from '@smu-chile/pkg-unimarc-hooks'
const PRODUCT_NOTES = 'productNotes'

const getStorage = (): string => {
  if (!Cookies.get(PRODUCT_NOTES)) {
    const domain = getCurrentDomain()
    Cookies.set(PRODUCT_NOTES, '{}', { domain, path: '/' })
  }
  return Cookies.get(PRODUCT_NOTES)
}

const getNoteByProductId = (productId: string): string => {
  const notes = getStorage()
  return (Object(JSON.parse(notes))[productId] ?? '') as string
}

const addProductNote = (productId: string, note: string): void => {
  const notes = Object(JSON.parse(getStorage()))
  const domain = getCurrentDomain()
  Cookies.set(PRODUCT_NOTES, JSON.stringify({
    ...notes,
    [productId]: note
  }), { domain, path: '/' })
}

const removeProductNote = (productId: string): void => {
  const jsonNotes = JSON.parse(getStorage())
  delete jsonNotes[productId]
  const domain = getCurrentDomain()
  Cookies.set(PRODUCT_NOTES, JSON.stringify({
    ...jsonNotes
  }), { domain, path: '/' })
}

export default {
  addProductNote,
  getNoteByProductId,
  getStorage,
  removeProductNote
}