import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import {
  CustomPortal,
  MembershipModal
} from '@smu-chile/pkg-unimarc-components'
import {
  Cookies,
  datalayerSimpleEvent,
  getCurrentDomain,
  trigger,
  useEvents,
  useMembershipData,
  useMembershipSavings,
  useMobile,
  useNewCartUnimarc
} from '@smu-chile/pkg-unimarc-hooks'
import { IMembershipResponse } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IMembership'
import { ToastWrapper } from './ToastWrapper'
import { dataLayerMembership } from './helpers/dataLayerMembership'

export const MembershipWrapper = () => {
  const router = useRouter()
  const domain = getCurrentDomain()
  const { isMobile } = useMobile()
  const [membershipResponse, setMembershipResponse] =
    useState<IMembershipResponse>()
  const [eventContext, setEventContext] = useState('')

  const { data, refetch } = useNewCartUnimarc()
  const { refetch: refetchSavings } = useMembershipSavings()

  useEvents({
    eventType: 'membershipToast',
    callBack: ({ detail }) => {
      setMembershipResponse(detail)
      if (detail?.success && router?.pathname === '/club-unimarc') {
        Cookies.set(
          'showMembershipToast',
          JSON.stringify({ ...detail, animationFinished: true }),
          {
            domain
          }
        )
      }
    }
  })

  useEvents({
    eventType: 'openMembershipModal',
    callBack: ({ detail }) => {
      setEventContext(detail?.eventContext)
      if (detail?.eventContext) {
        const dataLayer = dataLayerMembership({
          status: data?.membershipInfo?.membership,
          eventContext: detail?.eventContext,
          planSelected,
          type: 'open'
        })
        datalayerSimpleEvent({ ...dataLayer })
      }
      handleOpenMembershipModal()
    }
  })

  const handleCloseToastMessage = () => {
    trigger({
      eventType: 'membershipToast',
      data: {
        showTooltip: false
      }
    })

    if (membershipResponse?.success) {
      Cookies.remove('showMembershipToast', {
        domain
      })
    }
  }

  const {
    animationFiles,
    availableCards,
    coconutImg,
    dataHasFailed,
    handleChooseCard,
    handleCloseMembershipModal,
    handleGetMembership,
    handleOpenMembershipModal,
    handleRefreshData,
    handleSelectPaymentMethod,
    handleSelectPlan,
    handleSetCard,
    handleStopAnimation,
    handleToggleTerms,
    handleToggleModalCards,
    handleToggleModalListPaymentMethods,
    isLoading,
    isLoadingModalCards,
    isLoadingTrialTime,
    isLoadingANewCard,
    labelBtn,
    membershipData,
    membershipStatus,
    modalTitle,
    openMembershipModal,
    openModalCards,
    openModalListPaymentMethods,
    planSelected,
    selectedCard,
    selectedTemporalCard,
    toggleTerms
  } = useMembershipData()

  const handleMembership = () => {
    if (eventContext) {
      const dataLayer = dataLayerMembership({
        status: data?.membershipInfo?.membership,
        eventContext,
        planSelected,
        type: 'button'
      })
      datalayerSimpleEvent({ ...dataLayer })
    }
    handleGetMembership()
  }

  useEffect(() => {
    if (!isLoadingTrialTime) {
      setMembershipResponse({
        ...membershipResponse,
        animationFinished: true
      })
      refetch()
      refetchSavings()
    }
  }, [isLoadingTrialTime])

  useEffect(() => {
    const showToast = JSON.parse(Cookies.get('showMembershipToast') ?? '{}')
    if (showToast?.showTooltip) {
      setMembershipResponse(showToast)
    }
  }, [])

  return (
    <>
      {membershipResponse?.animationFinished &&
        membershipResponse?.showTooltip && (
          <CustomPortal
            rootId={membershipResponse?.success ? '' : 'portal-membership'}
          >
            <ToastWrapper
              errorCode={membershipResponse?.errorCode}
              eventContext={eventContext}
              membershipStatus={data?.membershipInfo?.membership}
              message={membershipResponse?.message}
              onClose={handleCloseToastMessage}
              planSelected={planSelected}
              success={membershipResponse?.success}
            />
          </CustomPortal>
        )}
      {openMembershipModal && (
        <MembershipModal
          animationFiles={animationFiles}
          coconutImg={coconutImg}
          dataHasFailed={dataHasFailed}
          handleClose={handleCloseMembershipModal}
          handleGetMembership={handleMembership}
          handleRefreshData={handleRefreshData}
          handleSelectPlan={handleSelectPlan}
          handleStopAnimation={handleStopAnimation}
          handleToggleTerms={handleToggleTerms}
          isLoading={isLoading}
          isLoadingTrialTime={isLoadingTrialTime}
          isOpenModalCards={openModalCards}
          isOpenModalPaymentMethods={openModalListPaymentMethods}
          labelBtn={labelBtn}
          membershipData={membershipData}
          membershipResponse={membershipResponse}
          membershipStatus={membershipStatus}
          modalListNewPaymentsProps={{
            bannerAvailablePaymentMethods:
              membershipData?.modalAvailablePaymentMethods
                ?.bannerAvailablePaymentMethods,
            handleGoBack: handleToggleModalCards,
            handleSelectPaymentMethod: handleSelectPaymentMethod,
            handleToggle:
              !openModalListPaymentMethods || isMobile
                ? handleToggleModalListPaymentMethods
                : handleCloseMembershipModal,
            paymentMethods: membershipData?.modalAvailablePaymentMethods,
            total: planSelected?.subtitulo2 as string,
            isLoading: isLoadingANewCard
          }}
          modalListPaymentsCardsProps={{
            cardsToList: availableCards,
            handleContinue: handleSetCard,
            handleSelectCard: handleChooseCard,
            handleToggle:
              !openModalCards || isMobile
                ? handleToggleModalCards
                : handleCloseMembershipModal,
            handleTooglePaymentMetods: handleToggleModalListPaymentMethods,
            isLoading: isLoadingModalCards,
            selectedCard: selectedTemporalCard,
            total: planSelected?.subtitulo2 as string
          }}
          modalTitle={modalTitle}
          planSelected={planSelected}
          selectedCard={selectedCard}
          toggleTerms={toggleTerms}
        />
      )}
    </>
  )
}
